import {apiClient as client} from '@/shared/service/api-client';

export default {
  async getHomePageStatsRange(params) {
    return await client.get('/customer/getHomePageStatsRange?', {params: params});
  },
  async getHomePageStatsValue() {
    return await client.get('/customer/getHomePageStatsValue');
  },
  async getCustomerStatsRange(params) {
    return await client.get('/channelQrcode/getCustomerStatsRange', {params: params});
  },
  async getCustomerStatsValue(params) {
    return await client.get('/channelQrcode/getCustomerStatsValue', {params: params});
  },
}
